
// Registeration 
import Login from '../components/registeration/login.vue'; 
import Products from '../components/product/Products.vue'; 
import Home from '../components/layouts/TheHome.vue'; 
 
import Vue from 'vue' 
import VueRouter from 'vue-router'; 
Vue.use(VueRouter); 
const routes = new VueRouter({ 
    mode: 'history', 
    routes: [{  
            path: '/', 
            name: 'Home', 
            component: Home, 
        }, 
        { 
            path: '/login', 
            name: 'login', 
            component: Login, 
        }, 
        { 
            path: '/sign-up', 
            name: 'sign-up', 
            component: ()=> import('../components/registeration/sign-up.vue'), 
        }, 
        { 
            path: '/forgot-password', 
            name: 'forgot-password', 
            component: ()=> import('../components/registeration/forgot-password.vue'), 
        }, 
        { 
            path: '/products/:type/:id', 
            name: 's-products', 
            component: ()=> import('../components/product/Products.vue'), 
        }, 
        { 
            path: '/products', 
            name: 'g-products', 
            component: Products, 
        }, 
        { 
            path: '/product/:productId', 
            name: 'product', 
            component: ()=> import('../components/product/product.vue'), 
        }, 
        { 
            path: '/account', 
            name: 'account', 
            component: ()=> import('../components/User/UserAccount.vue'), 
            meta: { 
                requiresAuth: true 
            } 
        }, 
        { 
            path: '/checkout', 
            name: 'checkout', 
            component: ()=> import('../components/UserOrder/UserCheckoutOrder.vue'), 
            meta: { 
                requiresAuth: true 
            } 
        }, 
        { 
            path: '/orders', 
            name: 'orders', 
            component: ()=> import('../components/UserOrder/UserOrders.vue'), 
            meta: { 
                requiresAuth: true 
            } 
        }, 
        { 
            path: '/order/:id', 
            name: 'order', 
            component: ()=> import('../components/UserOrder/UserOrder.vue'), 
        }, 
        { 
            path: '/wallet', 
            name: 'wallet', 
            component: ()=> import('../components/User/UserWallet.vue'), 
            meta: { 
                requiresAuth: true 
            } 
        }, 
        { 
            path: '/cart', 
            name: 'cart', 
            component: ()=> import('../components/User/UserCart.vue'), 
            meta: { 
                requiresAuth: true 
            } 
        }, 
        { 
            path: '/wishlist', 
            name: 'wishlist', 
            component: ()=> import('../components/User/UserWishlist.vue'), 
            meta: { 
                requiresAuth: true 
            } 
        }, 
        { 
            path: '/addresses', 
            name: 'addresses', 
            component: ()=> import('../components/User/UserAddress.vue'), 
            meta: { 
                requiresAuth: true 
            } 
        }, 
        { 
            path: '/stores',  
            name: 'stores',  
            component: ()=> import('../components/stores/stores.vue')  ,  
        }, 
        { 
            path: '*', 
            redirect: '/' 
        } 
    ] 
}) 
 
routes.beforeEach((to, from, next) => { 
 
    const { uri } = to.query; 
    if (uri != null && uri != '/') { 
        next(false); 
        routes.push(uri); 
    } else { 
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth); 
        if (requiresAuth && !localStorage.getItem('token')) next('login') 
        else next(); 
    } 
}); 
 
export default routes 