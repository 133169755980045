<template>
<div style="background-color:white;">
    <div v-if="node.name == 'التصنيفات'" >
        <div style="padding-bottom: 2.5rem;" class="widget mt-2">
            <h3 class="widget-title">
                <a @click="expanded = !expanded" class="collapsed">
                    <p class="h3 text-right">{{ node.name }}</p>
                </a>
            </h3>
        </div>

    </div>
    <div v-else style="margin-top : 20px"  >
              
        <a href="#" @click="addSearchCat(node)" v-if="node.subCategories.length === 0"> 
            {{ node.name }}
        </a>  
        <span v-else href="#" @click="expanded = !expanded" >
            {{ node.name }}
        </span> 
    </div>
    <div  v-if="expanded">
        <div v-for="category in node.subCategories" :key="category.id"
        :node="category">
         <div v-if="category.isActive === true">
            <TreeBrowser 
            :key="category.id"
            :node="category"
            />  
         </div>
        </div>
    </div>
</div>

</template>

<script>

export default {
    name: 'TreeBrowser',
    props: {
        node:Object,
    },
    data(){
        return {
            expanded:true
        }
    },
    methods : {
        addSearchCat: function(element) {
            console.log(element);
            this.$root.$refs.products_component.addSearch("categories",element);
        }
    }
}
</script>

<style>

</style>