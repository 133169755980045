<template>
    <main class="main" dir="rtl">
        <div class="container row">
            <div class="col-md-6 login-form">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="text-dark">تسجيل الدخول</h1>
                        <div class="heading">
                        </div>
                        <!-- End .heading -->

                        <form @submit.prevent="handleLoginSubmit()" style="text-align: -webkit-center;">
                            <input v-model="user.username" type="text" class="form-control" placeholder="البريد الإلكتروني">
                            <input v-model="user.password" :min="8" type="password" class="form-control" placeholder="كلمة المرور">
                            <p v-if="error" class="text-danger">{{ this.error }}</p>
                            <div class="form-footer">
                                <button type="submit" class="btn btn-primary"> تسجيل </button>  
                            </div>
                            <a href="/forgot-password" class="forget-pass ml-4"> لقد نسيت كلمة المرور ؟ </a>
                            <a href="/sign-up" class="forget-pass"> انشاء حساب جديد </a>
                            <!-- End .form-footer -->
                        </form>
                    </div>
                    <!-- End .col-md-6 -->
                </div>
                <!-- End .row -->
            </div>
            <div class="col-md-4">
                <img src="../../assets/images/login/Login-amico (1).png" alt="">
            </div>
        </div>
        <!-- End .container -->
    </main>
</template>

<script>

export default {
    data() {
        return {
            user: {
                username: '',
                password: '',
            },
            error: '',
        }
    },
    methods: {
        handleLoginSubmit() { 
        this.$store.dispatch('login', this.user) 
                    .then(() => { 
                        this.$router.push('/') 
                        this.$router.go() 
                    }) 
                    .catch(() => { 
                        this.error = 'البريد الالكتروني او كلمة المرور غير صحيحة'; 
                    })
        },
    },
}
</script>

<style scoped>
    .login-form{
        background-color: rgb(255, 255, 255);
        margin-right: 176px;
        margin-bottom: 38px;
        margin-top: 32px;
        padding: 31px;
        border-radius: 15px;
        font-family: 'Cairo', sans-serif;
    }
    h1{
        text-align: center;
        font-size: 35px;
        font-family: 'Cairo', sans-serif;
    }
    input{
        margin-right: 31px;
        font-family: 'Cairo', sans-serif;
    }
    button {
        font-family: 'Cairo', sans-serif;
    }
    a{
        font-family: 'Cairo', sans-serif;
    }
    .container, .form-footer {
        justify-content: center;
    }
</style>
    <!-- End .main -->