<template>
  <div id="app">
    <TheHeader/>
    <router-view></router-view>
    <TheFooter/>
    <UserLoginError></UserLoginError>
    <go-top :bottom="50" :size="60" bg-color="#29364b"></go-top>
  </div>
</template>


<script>

import GoTop from '@inotom/vue-go-top';
import TheHeader from "./components/layouts/TheHeader.vue";
import TheFooter from "./components/layouts/TheFooter.vue";

import UserLoginError from "./components/models/UserLoginError.vue";

export default {
  name: 'App',
  data () {
    return {
      dateCompare : false
    }
  },
  components: {
    TheHeader,
    TheFooter,
    GoTop,
    UserLoginError
  },
  mounted () {
    this.$store.dispatch('getCartCount');
  },
  created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout')
        }
        throw err;
      });
    });
  }
}
</script>

<style>
  li {
    cursor: pointer;
  }
  .xxxl {
    font-size: xxx-large;
  }
  .xxl {
    font-size: xx-large;
  }
  .xl {
    font-size: x-large;
  }
  .l {
    font-size: large;
  }
  .m {
    font-size: medium;
  }
</style>
