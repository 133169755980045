<template>
    <div class="page-wrapper">
        <header class="header">
            <div class="header-top">
                <div class="container">
                    <a href="/account" v-if="token">
                        <div class="header-left header-dropdowns">
                            <font-awesome-icon icon="user" class="mr-4"/>
                            {{ username ? username : '' }}
                        </div>
                    </a>
                    <div v-else class="header-left header-dropdowns"></div>
                    <div class="header-right">
                        <ul>
                            <li v-if="username"><a href="/account">حسابي</a></li>
                            <li><a href="#contact-us">تواصل معنا</a></li>
                            <li><a href="">عنا</a></li>
                            <li><a href="/login" v-if="!isLoggedIn">تسجيل الدخول</a>
                            <a href="" v-else @click="logout">تسجيل الخروج</a></li>
                        </ul>
                    </div>
                </div>
            </div><!-- End .header-top -->

            <div class="sticky-wrapper" style=""><div class="header-middle sticky-header">
                <div class="container">
                    <nav class="navbar navbar-expand-lg navbar-light bg-light container header-middle" dir="rtl" >
                        <a class="navbar-brand logo" href="#">
                            <img style="height:75px;" src="../../assets/images/logo/ezad logo.png" alt="Porto Logo">
                        </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                          <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav" style="margin-right: 51px;">
                            <ul class="navbar-nav">
                                <li class="nav-item active">
                                    <a class="nav-link" href="/">الرئيسية <span class="sr-only">(current)</span></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="/stores">المتاجر</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="/products">المنتجات</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="/products/discount/1">التخفيضات</a>
                                </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    التصنيفات
                                </a>
                                <div class="dropdown-menu dropdown-menu-left" aria-labelledby="navbarDropdown">
                                    <a v-for="category in categories" :key="category.id"  class="dropdown-item" :href="'/products/categories/'+category.id">{{ category.nameAr }}</a>
                                </div>
                            </li>
                            </ul>
                        </div>
                        <div class="form-inline my-2 my-lg-0">
                            <div class="header-right">
                                <div v-if="!token">
                                    <a class="porto-icon" v-b-modal.modal-1><i class="icon icon-wishlist-2"></i></a>
                                    <a class="porto-icon" v-b-modal.modal-1>
                                        <i class="fas fa-shopping-cart"></i>
                                    </a>
                                </div>
                                <div v-else class="header-right w-lg-max ml-0">
                                    <a href="/wishlist" class="porto-icon" title="WISHLIST"><i class="icon icon-wishlist-2"></i></a>
                                    <div class="dropdown cart-dropdown">
                                        <a href="/cart" class="dropdown-toggle dropdown-arrow porto-icon" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                                            <font-awesome-icon icon="shopping-cart"/>
                                            <span class="cart-count badge-circle" id="cart-count" >{{ cartCount }}</span>
                                        </a>

                                        <div class="dropdown-menu">
                                            <div class="dropdownmenu-wrapper">
                                                <div class="dropdown-cart-header">

                                                    <a href="cart.html" class="float-right cart-link">ادارة السلة الخاصة بك
                                                    </a>
                                                    <span>منتج {{ cartCount }}</span>
                                                </div><!-- End .dropdown-cart-header -->

                                                <div class="dropdown-cart-products" id="add-product-to-cart" ref="container">
                                                    
                                                    <div class="product">
                                                        
                                                    </div><!-- End .product -->

                                                </div><!-- End .cart-product -->
                                                   <a href="/cart">
                                                        <span style=" font-size: 15px; float: none; margin-right: 50%;">  المزيد ....  </span>
                                                    </a>

                                                <div class="dropdown-cart-total">
                                                    <span>  المجموع  </span>

                                                    <span class="cart-total-price float-right" id="cart-total-price" style="margin-right: 12px;"></span>
                                                </div><!-- End .dropdown-cart-total -->

                                                <div class="dropdown-cart-action">
                                                    <a href="checkout-shipping.html" class="btn btn-dark btn-block">الدفع الان</a>
                                                </div><!-- End .dropdown-cart-total -->
                                            </div><!-- End .dropdownmenu-wrapper -->
                                        </div><!-- End .dropdown-menu -->
                                    </div><!-- End .dropdown -->  
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script src="../../public/assets/js/plugins/isotope-docs.min.js"></script>

<script>
export default {
    data() {
        return {
            username: {},
            token: '',
            categories: [],
            cartCount: 0,
            cartItems: [],
            base_url: process.env.VUE_APP_URL,
        }
    },
    mounted () {
        this.$store.dispatch('getCartItems', this.product)
        .then(() => {
            document.getElementById("btnText").hidden = false
            this.spinner  = false
        }) 
        .catch(() => {
            document.getElementById("btnText").hidden = false
            this.error = "لقد قمت باضافة الكمية بالكامل لهذا المنتج";
            this.spinner  = false
        })
        this.axios
            .get(process.env.VUE_APP_URL+'/api/public/categories',{
                    headers: {  
                'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                this.categories = response.data;
            });

        this.username = localStorage.getItem('username');
        this.token = localStorage.getItem('token');
        this.cartCount = localStorage.getItem('cartCount');    
    },
    computed : {
        isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
    },
    methods: {
        logout: function () {
            this.$store.dispatch('logout')
            .then(() => {
                this.$router.push('/login')
            })
        },
        testme: function() {
            console.log("hello clicked")
        },
    },
}
</script>

<style scoped>
.search:focus {
    border: 1px solid #b9336e;
}
i:hover {
    color: #b9336e;
}
a:hover {
    color: #b9336e;
}
.minicart-icon:hover {
    color: #b9336e;
}
.header {
    background-color: #29364b;
}
.header-top {
    padding: 1.6rem 0;
    color: #fff;
    border-bottom: 1px solid #e7e7e7;
}
.cart-link {
    color: #29364b;
    font-size: 14px;
    font-weight: bold;
}
.cart-link:hover {
    text-decoration: revert;
}
</style>