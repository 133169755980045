<template>
    <div>
    <main class="home main">
        
        <vueper-slides class="w-100 h-55" autoplay  @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
            fixed-height="500px">
            <vueper-slide class="w-100 h-55" v-for="slider in sliders" :key="slider.imageUrl" :image='base_url+"/api/public/file/download/"+slider.imageUrl'/>
        </vueper-slides>
        <!-- <vueper-slides class="h-auto" autoplay :arrows="false" style="">
            <vueper-slide style="width:640px; height:640px;" v-for="slider in sliders" :key="slider.imageUrl" image="assets/images/main_slider.jpg"/>
        </vueper-slides> -->
        <div class="header-bottom">
                <div class="col-lg-4">
                    <div class="service-widget">
                        <div class="service-content">
                            <h3 class="service-title">شحن وإرجاع مجاني </h3>
                        </div>
                        <i class="service-icon icon-shipping"></i>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="service-widget">
                        <div class="service-content">
                            <h3 class="service-title">ضمان استعادة الاموال</h3>
                        </div>
                        <i class="service-icon icon-money"></i>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="service-widget">
                        <div class="service-content">
                            <h3 class="service-title">دعم عبر الإنترنت 24/7</h3>
                        </div>
                        <i class="service-icon icon-support"></i>
                    </div>
                </div>
            </div>
        <!-- <splide :slides="sliders">
            <splide-slide v-for="slider in sliders" :key="slider.imageUrl">
                        <img :src='"http://ezadhss.elzad.ly/api/public/file/download/"+slider.imageUrl' alt="slide image">
            </splide-slide>
        </splide> -->
         <div class="product-cat-box owl-carousel owl-theme mt-lg-9" data-toggle="owl" data-owl-options="{
                    'margin': 0,
                    'items': 2,
                    'loop' : false,
                    'autoplay' : false,
                    'dots': false,
                    'nav' : true,
                }">
                <div class="container">
                    <div class="row">
                        <div class="category-box col-12">
                            <div class="col-1"></div>
                            <div class="product-cat col-2 p-4" v-for="item in mainCategories.one" :key="item.id">
                                <a :href="'/products/categories/'+item.id">
                                    <img :src="base_url+'/api/public/file/download/' + item.imageUrl">
                                    <span class="p-0">{{ item.nameAr }}</span>
                                </a>
                            </div>
                            <div class="col-1"></div>
                        </div>
                        <div class="category-box col-12">
                            <div class="col-1"></div>
                            <div class="product-cat col-2 p-4" v-for="item in mainCategories.two" :key="item.id">
                                <a :href="'/products/categories/'+item.id">
                                    <img :src="base_url+'/api/public/file/download/' + item.imageUrl">
                                    <span class="p-0">{{ item.nameAr }}</span>
                                </a>
                            </div>
                            <div class="col-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="container mt-lg-9">
            <section class="product-panel background-slider">
                <div class="section-title mb-6">
                    <p class="xxl">العلامات التجارية</p>
                </div>
                <vueper-slides
                    fixed-height="200px"
                    class="no-shadow"
                    :arrows="false"
                    :visible-slides="6"
                    slide-multiple
                    :gap="1"
                    autoplay
                    :slide-ratio="1 / 4"
                    :dragging-distance="200"
                    :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }">
                    <vueper-slide v-for="brand in brands" :key="brand.id" :link="'/products/brands/'+brand.id" :image='base_url+"/api/public/file/download/"+brand.imageUrl'/>
                </vueper-slides>
                <div class="more">
                    <a href="/products">
                     ... المزيد
                    </a>
                </div>
            </section>
        </div>
<div class="container mt-lg-9">
<section class="product-panel">
    <div class="section-title">
        <h2 class="mr-5">Pre-Owned</h2>
        <a href="product.html">view all products<i class="icon-right"></i></a>
    </div>

    <div class="row row-sm grid" style="position: relative;">
        <div class="grid-item col-6 col-sm-3 col-lg-2 height-xl" v-for="product in recent_products" :key="product.id">
            <div class="product-default inner-quickview inner-icon">
                <figure>
                    <a href="product.html">
                        <img :src="base_url+'/api/public/file/download/'+product.imageUrl">
                    </a>
                    <div class="label-group">
                        <div class="product-label label-cut">27% off</div>
                    </div>
                    <div class="btn-icon-group">
                        <button class="btn-icon btn-add-cart" data-toggle="modal" data-target="#addCartModal"><i class="icon-bag"></i></button>
                    </div>
                    <a href="ajax/product-quick-view.html" class="btn-quickview" title="Quick View">Quick View</a> 
                </figure>
                <div class="product-details">
                    <div class="category-wrap">
                        <div class="category-list">
                            <a href="category.html" class="product-category">category</a>
                        </div>
                        <a href="#" class="btn-icon-wish"><i class="icon-heart"></i></a>
                    </div>
                    <h2 class="product-title">
                        <a href="product.html">Product Short Name</a>
                    </h2>
                    <div class="ratings-container">
                        <div class="product-ratings">
                            <span class="ratings" style="width:100%"></span><!-- End .ratings -->
                            <span class="tooltiptext tooltip-top"></span>
                        </div><!-- End .product-ratings -->
                    </div><!-- End .product-container -->
                    <div class="price-box">
                        <span class="old-price">$59.00</span>
                        <span class="product-price">$49.00</span>
                    </div><!-- End .price-box -->
                </div><!-- End .product-details -->
            </div>
        </div>

        <div class="grid-item col-sm-6 col-lg-4 height-xxl" style="position: absolute; left: 66.6667%; top: 0px;">
            <vueper-slides
                class="no-shadow"
                :visible-slides="1"
                :slide-ratio="1 / 4"
                :dragging-distance="70"
                fixed-height="420px"
                autoplay
                >
                <vueper-slide v-for="product in recent_products_second" :key="product.id"   :image='base_url+"/api/public/file/download/"+product.imageUrl' />
            </vueper-slides>
        </div>


    </div>
</section>

</div>
<div class="container mt-lg-9">
    <section>
        <div class="row row-sm grid" style="position: relative; height: 798px;">
            <div class="col-md-8 grid-item height-x1" style="position: absolute; left: 0%; top: 0px;">
                <div class="home-banner">
                    <figure>
                        <img src="../../assets/images/staticImg/246395161_4288497517936050_6234199895811873249_n.jpg">
                    </figure>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 grid-item height-x2" style="position: absolute; left: 66.6667%; top: 0px;">
                <div class="home-banner">
                    <figure>
                        <img src="../../assets/images/staticImg/259880606_4373740756078392_3263044864625106128_n.png">
                    </figure>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 grid-item height-x2" style="position: absolute; left: 66.6667%; top: 420px;">
                <div class="home-banner">
                    <figure>
                        <img src="../../assets/images/staticImg/271637718_4545282515590881_3011874515554985312_n.png">
                    </figure>
                </div>
            </div>
        </div>
    </section>
</div>
      <div  style=" dir:rtl; background-image: url('assets/images/banners/home_banner4.jpg'); background-repeat: no-repeat; background-size: cover; padding: 7rem 0 1rem;">
                <div class="container">
                    <section class="product-panel">
                        <div class="section-title">
                            <a href="product.html"><i class="icon-left"></i> عــرض جــمــيــع المنــتـجـات</a>
                            <h2 class="ml-5">الــمـنــتــجــات الاكـــثــر مبــيــعـا</h2>
                        </div>

                        <div class="row row-sm">
                            <div class="col-6 col-md-3" v-for="bestSeller in bestSellers" :key="bestSeller.id">    
                                <div class="product-default inner-quickview inner-icon">
                                    <figure>
                                        <a href="product.html">
                                            <div :style="'background-image: url('+base_url+'/api/public/file/download/'+bestSeller.imageUrl+');     margin-bottom: 24px;height: 300px;background-size: cover;background-position: center;'" >
                                            </div>
                                        </a>
                                        <div class="label-group">
                                            <div class="product-label label-cut">27% off</div>
                                        </div>
                                        <div class="btn-icon-group">
                                            <button class="btn-icon btn-add-cart" data-toggle="modal" data-target="#addCartModal"><i class="icon-bag"></i></button>
                                        </div>
                                        <a href="ajax/product-quick-view.html" class="btn-quickview" title="Quick View">Quick View</a> 
                                    </figure>
                                    <div class="product-details">
                                        <div class="category-wrap">
                                            <div class="category-list">
                                                <a href="category.html" class="product-category">category</a>
                                            </div>
                                            <a href="#" class="btn-icon-wish"><i class="icon-heart"></i></a>
                                        </div>
                                        <h2 class="product-title">
                                            <a href="product.html">Product Short Name</a>
                                        </h2>
                                        <div class="ratings-container">
                                            <div class="product-ratings">
                                                <span class="ratings" style="width:100%"></span><!-- End .ratings -->
                                                <span class="tooltiptext tooltip-top"></span>
                                            </div><!-- End .product-ratings -->
                                        </div><!-- End .product-container -->
                                        <div class="price-box">
                                            <span class="old-price">$59.00</span>
                                            <span class="product-price">$49.00</span>
                                        </div><!-- End .price-box -->
                                    </div><!-- End .product-details -->
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </div>
        <div class=" container mt-lg-9 home-banner mb-3 flex-wrap flex-md-nowrap">
            <div class="banner-left mb-1 mb-md-0 mx-auto ml-md-0 mr-md-3 col-sm-3">
                <img src="../../assets/images/staticImg/271136337_4527975507321582_148498652843446500_n.jpg" alt="">
            </div>
            <div class="banner-left mb-1 mb-md-0 mx-auto ml-md-0 mr-md-3 col-sm-3">
                <img src="../../assets/images/staticImg/271299803_4533852666733866_4804632062043342708_n.png" alt="">
            </div>
            <div class="banner-right col-sm-3">
                <img src="../../assets/images/staticImg/271435658_4536740069778459_7014394497419673092_n.jpg" alt="">
                <div class="banner-content">
                    <h2>مع الزاد مفيش مستحيل</h2>
                    <div class="mb-1">
                        <a href="/products" class="btn">تمتــع بالتـوسق</a>
                        <h3 class="align-middle d-inline">الـــزاد </h3>
                    </div>
                    <h4 class="cross-txt">افضل المنتجات</h4>
                </div>
            </div>
        </div>
        <div class="container mt-lg-9">
            <div v-show="discount_products.length">
                <section class="product-panel background-slider">
                    <div class="section-title">
                        <a href="/products-col/discount/1" class="xxl">تخفيضات</a>
                    </div>
                    <vueper-slides
                      class="no-shadow thumbnails"
                      fixed-height="300px"
                      :visible-slides="6"
                      slide-multiple
                      :slide-ratio="1 / 4"
                      :gap="1"
                      :dragging-distance="200"
                      :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 1 } }">
                       <vueper-slide class="image" v-for="product in discount_products" :key="product.id" :title="product.price+' LYD'" :content="product.salePrice+' LYD'" :link="'/product/'+product.id" :image='base_url+"/api/public/file/download/"+product.imageUrl'/>
                    </vueper-slides>
                    <div class="more">
                        <a href="/products/discount/1">
                         ... المزيد
                        </a>
                    </div>
                </section>
                
            </div>
           
        </div>
        
    </main>
    
    </div>
    
</template>

<script src="../../public/assets/js/plugins/isotope-docs.min.js"></script>
<script>
// import { Carousel3d, Slide } from 'vue-carousel-3d';

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import {Swiper} from 'vue2-swiper'

export default {
    data() {
        return {
            brands: [],
            base_url: process.env.VUE_APP_URL,
            sliders: [],
            recent_products: [],
            discount_products: [],
            recent_products_second: [],
            bestSellers: [],
            mainCategories: {
                one: [],
                two: []
            },
            options: {
                type: 'loop',
                autoplay: true,
            },
        }
    },
    mounted() {
       console.log(process.env.VUE_APP_URL)
        this.axios
        .get(process.env.VUE_APP_URL+'/api/public/products/best-seller?size=4&sort=createdDate,asc',{ 
                headers: {'Access-Control-Allow-Origin': '*'} 
        }) 
        .then(response => { 
            this.bestSellers  = response.data 
            console.log(this.bestSellers);
        }) 
        this.axios
            .get(process.env.VUE_APP_URL+'/api/public/brands',{
                headers: {'Access-Control-Allow-Origin': '*'}
            })
            .then(response => {
                response.data.forEach(brand => {
                    if(brand.imageUrl) {
                        this.brands.push(brand);
                    }
                });
            });
        this.axios
            .get('http://ezadhss.elzad.ly/api/public/sliders',{
                headers: {'Access-Control-Allow-Origin': '*'}
            })
            .then(response => {
                console.log(response.data)
                this.sliders = response.data;
            });
        this.axios
            .get(process.env.VUE_APP_URL+'/api/public/products?vendorStatus.equals=OPEN&productStatus.equals=PUBLISHED&parentId.specified=false&sort=createdDate,desc&size=4&page=0',{
                headers: {'Access-Control-Allow-Origin': '*'}
            })
            .then(response => {
                this.recent_products = response.data
            })
        this.axios
            .get(process.env.VUE_APP_URL+'/api/public/products?vendorStatus.equals=OPEN&productStatus.equals=PUBLISHED&parentId.specified=false&sort=createdDate,desc&size=4&page=1',{
                headers: {'Access-Control-Allow-Origin': '*'}
            })
            .then(response => {
                this.recent_products_second = response.data
            })
        this.axios
            .get(process.env.VUE_APP_URL+'/api/public/products?isSale.equals=true&vendorStatus.equals=OPEN&productStatus.equals=PUBLISHED&parentId.specified=false&size=12',{
                headers: {'Access-Control-Allow-Origin': '*'}
            })
            .then(response => {
                this.discount_products = response.data
                console.log(this.discount_products);
            })
        this.getMainCategories();
    },
    components: {
        Splide,
        SplideSlide,
        VueperSlides,
        VueperSlide,
        Swiper
    },
    methods: {
        getMainCategories: function () {
            this.axios
                .get(process.env.VUE_APP_URL+'/api/public/categories?size=10',{
                    headers: {'Access-Control-Allow-Origin': '*'}
                })
                .then(response => {
                    for (let index = 0; index < response.data.length / 2; index++) {
                        this.mainCategories.one.push(response.data[index]);
                    }
                    for (let index = response.data.length / 2; index < response.data.length; index++) {
                        this.mainCategories.two.push(response.data[index]);
                    }
                });
        },
    }
}

</script>

<style scoped>
    .search:focus {
        border: 1px solid #b9336e;
    }
    i:hover {
        color: #b9336e;
    }
    a:hover {
        color: #b9336e;
    }
    .image:hover {
        opacity: 0.5;
        filter: alpha(opacity=40);
    }
    .background-slider {
        background: white;
        padding: 12px;
        padding-left:45px;
        padding-right:45px;
    }
    .category-box {
        display: flex;
        justify-content: center;
    }
    .category-box img {
        height: 60px;
        width: 70px;
    }
    .category-box a {
        display: flex;
        flex-direction: column;
        align-items: center
    }
    .vueperslides__arrow {
        top: 50%;
        background-color: none !important;
        border: none;
        opacity: .7;
    }
    .vueperslide__title {
        margin-top: -165px;
        background-color: white  !important;
        color: black;
        padding: 11px;
        border-radius: 5px;
    }
    
    .thumbnails {
    }
.vueperslides__arrow {
    top: 50%;
    background-color: none !important;
    border: none;
    opacity: .7;
}
.arrive-now {
        max-width: 104% !important;
}
.product-default figure img:first-child {
    position: relative;
    width: auto;
    opacity: 1;
    height: 259px;
}
</style>