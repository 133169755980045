 import Vue from 'vue'
 import Vuex from 'vuex'
 import axios from 'axios'
 
 Vue.use(Vuex)
 
 let store = new Vuex.Store({
     state: {
         cartItems: 0,
         cartLimit: 0,
         status: '',
         token: localStorage.getItem('token') || '',
         user: {}
     },
     mutations: {
         auth_request(state) {
             state.status = 'loading'
         },
         auth_success(state, { token, user }) {
             state.status = 'success';
             state.token = token;
             state.user = user;
         },
         auth_error(state) {
             state.status = 'error';
         },
         logout(state) {
             state.status = ''
             state.token = ''
         },
     },
     actions: {
         login({ commit }, user) {
             return new Promise((resolve, reject) => {
                 commit('auth_request')
                 axios({ url: process.env.VUE_APP_URL+'/api/authenticate', data: user, method: 'POST' })
                     .then(resp => {
                         const token = resp.data.id_token
                         localStorage.setItem('token', token)
                         axios.defaults.headers.common['Authorization'] = token
                         commit('auth_success', { token: token, user: user })
                         axios({
                                 url: process.env.VUE_APP_URL+'/api/customers/get-profile',
                                 method: 'GET',
                                 headers: {
                                     'Authorization': 'Bearer ' + localStorage.getItem('token')
                                 }
                             })
                             .then(response => {
                                 localStorage.setItem('username', response.data.name)
                                 localStorage.setItem('customer_id', response.data.id)
                                 axios({
                                         url: process.env.VUE_APP_URL+'/api/cart-items/count-for-customer',
                                         method: 'GET',
                                         headers: {
                                             'Authorization': 'Bearer ' + localStorage.getItem('token')
                                         }
                                     })
                                     .then(response => {
                                         localStorage.setItem('cartCount', response.data)
                                         console.log(response.data)
                                         resolve(response)
                                     });
                             })
                     })
                     .catch(err => {
                         commit('auth_error')
                         localStorage.removeItem('token')
                         localStorage.removeItem('username')
                         localStorage.removeItem('customer_id')
                         reject(err)
                     })
             })
         },
         sign_up({ commit }, user) {
             return new Promise((resolve, reject) => {
                 commit('auth_request')
                 axios({ url: process.env.VUE_APP_URL+'/api/public/customers/register', data: user, method: 'POST' })
                     .then(resp => {
                         resolve(resp)
                     })
                     .catch(err => {
                         console.log(err)
                         commit('auth_error', err)
                         localStorage.removeItem('token')
                         reject(err)
                     })
             })
         },
         logout({ commit }) {
             return new Promise((resolve) => {
                 commit('logout')
                 localStorage.removeItem('token')
                 localStorage.removeItem('username')
                 localStorage.removeItem('customer_id')
                 delete axios.defaults.headers.common['Authorization']
                 resolve()
             })
         },
         add_cart({ commit },cart) {
            return new Promise((resolve, reject) => {
                 commit('auth_error')
                 axios({
                     url: process.env.VUE_APP_URL+'/api/cart-items',
                     data: {
                         customerId: localStorage.getItem('customer_id'),
                         product: cart,
                         productId: cart.id,
                         productName: cart.name,
                         quantity: 1
                     },
                     method: 'POST',
                     headers: {
                         'Authorization': 'Bearer ' + localStorage.getItem('token')
                     }
                 })
                 .then(res => {
                     console.log(res)
                     axios({
                         url: process.env.VUE_APP_URL+'/api/cart-items/count-for-customer',
                         method: 'GET',
                         headers: {  
                             'Authorization': 'Bearer ' + localStorage.getItem('token')
                         }
                     })
                    .then(response => {
                        localStorage.setItem('cartCount', response.data)
                        document.getElementById('cart-count').textContent = localStorage.getItem('cartCount')
                        resolve()
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })                  
                })  
            })
         },
         getCartItems() {
            axios({ 
                url : process.env.VUE_APP_URL+'/api/cart-items?page=0&size=100000',
                method: 'GET',
                headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('token') 
                } 
            })
            .then(response => { 
                this.total = 0
                document.getElementById('add-product-to-cart').innerHTML = ""
                response.data.forEach(element => {
                    document.getElementById('add-product-to-cart').innerHTML += ` <div class='product'>
                    <div class="product-details">
                        <h4 class="product-title" style="text-align: -webkit-right;">
                            <a href="product.html">${element.productName }</a>
                        </h4>

                        <span class="cart-product-info" >
                            <span class="cart-product-qty" style="padding-left: 12px; float: left;"> ${element.quantity }</span>
                                x
                                ${element.product.price+" LYD"}
                        </span>
                    </div><!-- End .product-details -->

                    <figure class="product-image-container">
                        <a href="product.html" class="product-image">
                            <img src="${process.env.VUE_APP_URL}/api/public/file/download/${element.product.imageUrl}" alt="product" width="80" height="80">
                        </a>
                        <a href="/cart" class="btn-remove icon-cancel" id="remove-product"   title="Remove Product">x</a>
                    </figure>
                    </div>`
                    });
                    axios({
                        url: process.env.VUE_APP_URL+'/api/cart-items/total',
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                        })
                    .then(response => {
                        document.getElementById('cart-total-price').textContent = response.data+" LYD"
                        });
               
            
            }).catch(err =>{
                console.log(err)
            })
         },
         getCartCount() {
             if (localStorage.getItem('token')) {
                axios({
                    url: process.env.VUE_APP_URL+'/api/cart-items/count-for-customer',
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                    })
                .then(response => {
                        localStorage.setItem('cartCount', response.data)
                    });
             }
         },
         wishlist({ commit }, id) {
             commit('auth_error')
             axios({
                 url: process.env.VUE_APP_URL+'/api/favorites/toggle/' + id,
                 method: 'GET',
                 headers: {
                     'Authorization': 'Bearer ' + localStorage.getItem('token')
                 }
             })
         }
     },
     getters: {
         isLoggedIn: state => !!state.token,
         authStatus: state => state.status,
         username: state => state.user.username,
     }
 })
 
 export default store;