<template> 
<div>
    <main class="product main">
        <img v-if="type == 'discount'" src="../../assets/images/sale.png" alt="">
        <img v-else src="../../assets/images/online shopping.png" alt="">
        
        <nav aria-label="breadcrumb" class="breadcrumb-nav">
            <div class="container">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Products</li>
                </ol>
            </div>
        </nav>
        
        <div class="container">
            <div class="row row-sm">
                
                <!-- model for not login  -->
                <aside class="sidebar-shop col-lg-2 mt-5">
                    <div class="sidebar-wrapper">
                        <div style="padding-bottom: 2.5rem;" class="widget widget-border">
                            <h3 class="widget-title">
                                <a data-toggle="collapse" href="#widget-brands" role="button" aria-expanded="false" aria-controls="widget-cat" class="collapsed">
                                    <p class="h3 text-right">العلامات التجارية</p>
                                </a>
                            </h3>
                            <div class="collapse" id="widget-brands">
                                <div class="widget-body">
                                    <ul class="cat-list" id="brands">
                                        <h3 class="mt-1 mb-2 widget-title-category" v-for="(brand, i) in brands" :key="i" @click="addSearch('brands', brand)">
                                            <a>
                                                <li style="text-align: right;">{{ brand.nameAr }}</li>
                                            </a>
                                        </h3>
                                    </ul>
                                </div><!-- End .widget-body -->
                            </div><!-- End .collapse -->
                        </div><!-- End .widget -->

                         <div style="padding-bottom: 2.5rem;" class="widget widget-border mt-2">                                
                          <!--  <h3 class="widget-title">
                                <a data-toggle="collapse" href="#widget-cat" role="button" aria-expanded="false" aria-controls="widget-cat" class="collapsed">
                                    <p class="h3 text-right">التصنيفات</p>
                                </a>
                            </h3>
                            <div class="collapse" id="widget-cat">
                                <div class="widget-body">
                                    <ul> -->
                                    <TreeBrowser :node="categories" />
                                        <!-- <div v-for="(category, i) in categories" :key="i" class="mb-2">
                                            <div v-if="category.subCategories.length != 0">
                                                <h3 class="mt-1 widget-title">
                                                    <a data-toggle="collapse" v-bind:href="'#widget-body-'+i" role="button" aria-expanded="false" class="collapsed" aria-controls="widget-body-2">
                                                        <li class="sub-cat">{{ category.nameAr }}</li>
                                                    </a>
                                                </h3>
                                                <div class="collapse" v-bind:id="'widget-body-'+i">
                                                    <div class="widget-body">
                                                        <ul>
                                                            <a @click="addSearch('categories', category)">
                                                                <li class="sub-cat">{{ category.nameAr }}</li>
                                                            </a>
                                                            <a v-for="(subCategory, i) in category.subCategories" :key="i" @click="addSearch('categories', subCategory)">
                                                                <li class="sub-cat">{{ subCategory.nameAr }}</li>
                                                            </a>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3 v-else class="mt-1 mb-2 widget-title-category ">
                                                <a @click="addSearch('categories', category)">
                                                    <li class="sub-cat">{{ category.nameAr }}</li>
                                                </a>
                                            </h3>
                                        </div> -->
                                    <!-- </ul>
                                </div>
                            </div>-->
                        </div> 

                        <div style="padding-bottom: 2.5rem;" class="widget widget-border mt-2">
                            <h3 class="widget-title">
                                <a data-toggle="collapse" href="#widget-vendors" role="button" aria-expanded="false" aria-controls="widget-cat" class="collapsed">
                                    <p class="h3 text-right">الاسواق</p>
                                </a>
                            </h3>
                            <div class="collapse" id="widget-vendors">
                                <div class="widget-body">
                                    <ul class="cat-list">
                                        <h3 class="mt-1 mb-2 widget-title-category" v-for="(vendor, i) in vendors" :key="i" @click="addSearch('vendors', vendor)">
                                            <a>
                                                <li style="text-align: right;">{{ vendor.name }}</li>
                                            </a>
                                        </h3>
                                    </ul>
                                </div><!-- End .widget-body -->
                            </div><!-- End .collapse -->
                        </div><!-- End .widget -->

                    </div><!-- End .sidebar-wrapper -->
                </aside><!-- End .col-lg-3 -->
                <div class="col-lg-10">
                        <nav class="toolbox">
                            <div class="toolbox-left">
                                <div class="toolbox-item toolbox-sort">

                                    <div v-if="type == 'discount'">
                                        <a @click="removeSearchDiscount()">
                                            <font-awesome-icon icon="times" class="mr-4"/>
                                        </a>
                                        <button class="search-button">
                                            تخفيضات
                                        </button>
                                    </div><!-- End .select-custom -->
                                    <div v-for="(brand, i) in search.brands" :key="i">
                                        brand : 
                                        <a @click="removeSearchBrand(i)">
                                            <font-awesome-icon icon="times" class="mr-4"/>
                                        </a>
                                        <button class="search-button">
                                            {{brand.name}}
                                        </button>
                                    </div><!-- End .select-custom -->
                                    <div v-for="(vendor, i) in search.vendors" :key="i">
                                        vendor : 
                                        <a @click="removeSearchVendor(i)">
                                            <font-awesome-icon icon="times" class="mr-4"/>
                                        </a>
                                        <button class="search-button">
                                            {{vendor.name}}
                                        </button>
                                    </div><!-- End .select-custom -->
                                    <div v-for="(category, i) in search.categories" :key="i">
                                        category : 
                                        <a @click="removeSearchCategory(i)">
                                            <font-awesome-icon icon="times" class="mr-4"/>
                                        </a>
                                        <button class="search-button">
                                            {{category.name}}
                                        </button>
                                    </div><!-- End .select-custom -->

                                </div><!-- End .toolbox-item -->
                            </div><!-- End .toolbox-left -->

                            <div class="toolbox-item toolbox-show">
                            </div><!-- End .toolbox-item -->

                            <div class="layout-modes">
                                <a href="" @click.prevent="changeListDisplay(false)" id="grid" class="layout-btn btn-grid active" title="Grid">
                                    <i class="icon-mode-grid"></i>
                                </a>
                                <a href="" @click.prevent="changeListDisplay(true)" id="list" class="layout-btn btn-list" title="List">
                                    <i class="icon-mode-list"></i>
                                </a>
                            </div><!-- End .layout-modes -->
                        </nav>
                        <!-- loading -->
                        <div class="row row-sm" v-if="isLoading == false && listDisplay == false"> 
                            <b-button variant="primary" disabled v-if="isLoading == false">
                            <b-spinner small type="grow"></b-spinner>
                            جاري التحميل ....
                            </b-button>
                            <div class="col-6 col-md-4 col-xl-3"  v-for="(n, i) in 16" :key="i">
                                <div class="product-default inner-quickview inner-icon">
                                    <figure>
                                        <b-skeleton-img animation="wave"  no-aspect height="150px">
                                        </b-skeleton-img>
                                    </figure>
                                    <div class="product-details">
                                        <b-skeleton animation="wave" width="85%"></b-skeleton>
                                        <div class="ratings-container">
                                            <div class="product-ratings">
                                                <span class="ratings" style="width:55%"></span><!-- End .ratings -->
                                                <span class="tooltiptext tooltip-top"></span>
                                            </div><!-- End .product-ratings -->
                                        </div><!-- End .product-container -->
                                        <b-skeleton animation="wave" width="70%"></b-skeleton>
                                    </div><!-- End .product-details -->
                                </div>
                            </div>
                        </div>
                        <div v-if="isLoading == false && listDisplay == true" class="row row-sm" dir="rtl">
                            <b-button variant="primary" disabled v-if="isLoading == false">
                            <b-spinner small type="grow"></b-spinner>
                            جاري التحميل ....
                            </b-button>
                            <div class="col-6  col-sm-12" v-for="(n, i) in 16" :key="i">
                                <div class="product-default inner-quickview inner-icon">
                                    <figure style="width: 298px;">
                                        <b-skeleton-img animation="wave"  no-aspect height="150px">
                                        </b-skeleton-img>
                                    </figure>
                                    <div class="product-details">
                                        <b-skeleton animation="wave" width="32%"></b-skeleton>
                                        <div class="ratings-container">
                                            <div class="product-ratings">
                                                <span class="ratings" style="width:55%"></span><!-- End .ratings -->
                                                <span class="tooltiptext tooltip-top"></span>
                                            </div><!-- End .product-ratings -->
                                        </div><!-- End .product-container -->
                                        <b-skeleton animation="wave" width="45%"></b-skeleton>
                                    </div><!-- End .product-details -->
                                </div>
                            </div>
                        </div><!-- End .row -->     

                        <div v-if="isLoading == true && listDisplay == true" class="row row-sm" dir="rtl">
                            <div v-if="products.length == 0" class="col-6 col-md-12 col-xl-12">
                                <img class="no-data-img" src="../../assets/images/notFound/No data-pana.png" alt="">
                            </div>
                            <div class="col-md-12 col-sm-12 product-default left-details product-list" v-for="product in products.data" :key="product.id">
                                <figure class="col-md-6">
                                    <a v-bind:href="product.parentId == null ? '/product/'+product.id : '/product/'+product.parentId" v-bind:id="'imagePr'+product.id" 
                                    class="display-none"
                                    >
                                        <img class="product-img-list" @load="loaded(product.id)"  :src="base_url+'/api/public/file/download/' + product.imageUrl">
                                    </a>
                                    <a :id="'imagePrLoad'+product.id" dir="ltr">
                                        <b-skeleton-img animation="wave"  height="196px" width="260px">
                                        </b-skeleton-img>
                                    </a>
                                    <div class="label-group" v-if="product.isSale">
										<div class="product-label label-hot"></div>
										<div class="product-label label-sale">Sale</div>
									</div>
                                </figure>
                                
                                <div class="product-details">
                                    <div class="category-list">
                                        <a class="product-category">{{ product.vendorName }}</a>,
                                        <a class="product-category">{{ product.brandName}}</a>
                                    </div>
                                    <h2 class="product-title">
                                        <a :href="product.parentId == null ? '/product/'+product.id : '/product/'+product.parentId">{{ product.name }}</a>
                                    </h2>
                                    <div class="ratings-container">
                                        <div class="product-ratings">
                                            <span class="ratings" style="width:100%"></span><!-- End .ratings -->
                                            <span class="tooltiptext tooltip-top"></span>
                                        </div><!-- End .product-ratings -->
                                    </div><!-- End .product-container -->
                                    <p class="product-description" v-html="product.shortDescription">
                                        <!-- {{  product.shortDescription }}     -->
                                    </p>
                                    <div class="price-box" v-if="product.isSale">
                                        <span class="old-price">{{product.price}}LYD </span>
                                        <span class="product-price">{{ product.salePrice+" LYD" }} </span>
                                    </div>
                                    <div class="price-box" v-else>
                                        <span class="product-price">{{ product.price }} LYD</span>
                                    </div><!-- End .price-box -->
                                    <div class="product-action">
                                        <div v-if="token">
                                            <a   @click="wishlist(product.id)" v-if="favorites.some(item => item.productId === product.id)" class="btn-icon-wish">
                                                    <font-awesome-icon icon="heart" class="active" :id="'wishlist_list'+product.id"/>
                                            </a>
                                            <a v-else  @click="wishlist(product.id)" class="btn-icon-wish">
                                               <font-awesome-icon icon="heart"  :id="'wishlist_list'+product.id"/>
                                            </a>
                                        </div>
                                        <div v-else>
                                            <a  class="btn-icon-wish" v-b-modal.modal-1>
                                                <font-awesome-icon icon="heart"/>
                                            </a>
                                        </div>
                                    </div>
                                </div><!-- End .product-details -->
                            </div>
                        </div><!-- End .row --> 

                        <div v-if="isLoading == true && listDisplay == false" class="row row-sm">
                            <div v-if="products.length == 0" class="col-6 col-md-12 col-xl-12">
                                <img class="no-data-img" src="../../assets/images/notFound/No data-pana.png" alt="">
                            </div>
                            <div class="col-6 col-md-12 col-xl-3" v-for="(product, i) in products.data" :key="i">
                                <div class="product-default inner-quickview inner-icon">
                                    <figure>
                                        <a :href="product.parentId == null ? '/product/'+product.id : '/product/'+product.parentId" :id="'imagePr'+product.id" 
                                        class="display-none"
                                        >
                                            <img class="product-img"  @load="loaded(product.id)"  :src="base_url+'/api/public/file/download/' + product.imageUrl">
                                        </a>
                                        <a :id="'imagePrLoad'+product.id">
                                            <b-skeleton-img animation="wave"  height="324px" width="329px">
                                            </b-skeleton-img>
                                        </a>
                                        <div class="label-group" v-if="product.isSale">
											<div class="product-label label-hot"></div>
											<div class="product-label label-sale">Sale</div>
										</div>
                                    </figure>
                                    <div class="product-details">
                                        <div class="category-wrap" >
                                            <div v-if="token">
                                                <a  @click.prevent="wishlist(product.id)" v-if="favorites.some(item => item.productId === product.id)">
                                                    <font-awesome-icon icon="heart" class="active" :id="'wishlist_'+product.id"/>
                                                </a>
                                                <a v-else @click.prevent="wishlist(product.id)" class="btn-icon-wish">
                                                    <font-awesome-icon icon="heart" :id="'wishlist_'+product.id" />
                                                </a>
                                            </div>
                                            <div v-else>
                                                <a class="btn-icon-wish" v-b-modal.modal-1><font-awesome-icon icon="heart"/></a>
                                            </div>
                                            <div class="category-list">
                                                <a class="product-category">{{ product.vendorName }}</a>
                                            </div>
                                        </div>
                                        <h2 class="product-title">
                                            <a :href="product.parentId == null ? '/product/'+product.id : '/product/'+product.parentId" >{{ product.name }}</a>
                                        </h2>
                                        <div class="ratings-container">
                                            <div class="product-ratings">
                                                <span class="ratings" style="width:100%">{{ product.rating }}</span><!-- End .ratings -->
                                                <span class="tooltiptext tooltip-top">{{ product.rating }}</span>
                                            </div><!-- End .product-ratings -->
                                        </div><!-- End .product-container -->
                                        <div class="price-box" v-if="product.isSale">
											<span class="old-price">{{product.price+" LYD"}} </span>
											<span class="product-price">{{ product.salePrice+" LYD" }} </span>
										</div>
                                        <div class="price-box" v-else>
                                            <span class="product-price">{{ product.price }} LYD</span>
                                        </div><!-- End .price-box -->
                                    </div><!-- End .product-details -->
                                </div>
                            </div>

                        </div><!-- End .row -->

                        <pagination dir="rtl" v-model="page" :records="countProducts" :per-page="16" @paginate="setPage" ref="bottomPagination"/>

                </div><!-- End .col-lg-10 -->
            </div><!-- End .row -->
        </div><!-- End .container -->

        <div class="mb-5"></div><!-- margin -->
    </main><!-- End .main -->
</div>
</template>
<style>
.active {
    color:#b9336e;
}
.product-img {
    height: 300px !important;
}
.product-img-list {
    height: 196px !important;
}

</style>

<script>

import "../../assets/css/products.css";
import TreeBrowser from '../helpers/TreeBrowser';

export default {
    name: 'products',
    data() {
        return {
            favorites    : [],
            products    : [],
            base_url    : process.env.VUE_APP_URL,
            isLoading: false,
            brands  : {},
            categories: {
                name :"التصنيفات",
                subCategories : [],
            },
            search : { 
                brands : [],
                categories : [],
                vendors : []
            },
            product_url: '',
            countProducts   : 0,
            currentPage : 0,
            page : 1,
            firstPage   : 0,
            LastPage    : 0,
            nextPage    : 0,
            previosPage : 0,
            type    : '',
            typeName    : '',
            typeId  : 0,
            token  : 0,
            listDisplay  : false,
            isLoad  : false,
            vendors : {},
            filter  : 'vendorStatus.equals=OPEN&productStatus.equals=PUBLISHED&parentId.specified=false',
			}
    },
    components : {
        TreeBrowser
    },
    created() {
        // set componenent name
        this.$root.$refs.products_component = this;
    },
    mounted () {
        this.getBrandsCategoriesVendors("brands");
        this.getBrandsCategoriesVendors("categories");
        this.getBrandsCategoriesVendors("vendors");
        this.token = localStorage.getItem('token') ? localStorage.getItem('token') : null
        if(this.$route.params.type){
            this.typeId = this.$route.params.id;
            this.type = this.$route.params.type;
            this.product_url = '/'+this.type+'/'+this.typeId
            if(this.type == "discount") {
                this.addSearch(this.type, {id : this.typeId, name: 'تخفيضات'});
            } else if(this.type == "brands") {
                this.axios
                .get(process.env.VUE_APP_URL+'/api/public/brands?id.equals='+this.typeId, {
                    headers: {'Access-Control-Allow-Origin': '*'}
                })
                .then(response => {
                    this.typeName = response.data[0].nameAr;
                    this.search.brands.splice(0,1);
                    this.search.brands.push({id : this.typeId, name: this.typeName})             
                })  
            }
            else if(this.type == "categories") {
                this.axios
                .get(process.env.VUE_APP_URL+'/api/public/categories?id.equals='+this.typeId, {
                    headers: {'Access-Control-Allow-Origin': '*'}
                })
                .then(response => {
                    this.typeName = response.data[0].nameAr;            
                    this.search.categories.splice(0,1);
                    this.search.categories.push({id : this.typeId, name: this.typeName})
                }) 
            }
            else if(this.type == "vendors") {
                this.axios
                .get(process.env.VUE_APP_URL+'/api/public/vendors?id.equals='+this.typeId, {
                    headers: {'Access-Control-Allow-Origin': '*'}
                })
                .then(response => {
                    this.typeName = response.data[0].name;            
                    this.search.vendors.splice(0,1);
                    this.search.vendors.push({id : this.typeId, name: this.typeName})
                }) 
            }
            this.addSearch(this.type, {id : this.typeId, name: this.typeName});
        }
        else {
            this.getProducts()
        }
        this.getFavorites();
    },
    methods : {
        getFavorites: function() {
            if(localStorage.getItem('token')) {
                this.axios
                .get(process.env.VUE_APP_URL+'/api/favorites',{
                    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
                })
                .then(response => {
                    this.favorites = response.data;
                })
            }
        },
        getProducts: function() {
        this.isLoading = false;
        this.countProducts = 0;
        this.axios
            .get(process.env.VUE_APP_URL+'/api/public/products?page=0&size=16&'+this.filter,{
                headers: {'Access-Control-Allow-Origin': '*'}
            })
            .then(response => {
                this.products = response
                this.countProducts = parseInt(this.products.headers["x-total-count"]);
                 this.isLoading = true;
            })
            this.product_url = ''
        },
        getSearchQuery: function() {
            var category = this.search.categories.length != 0 ? "categoryId.in="+this.search.categories[0].id : "";
            var brand = this.search.brands.length != 0 ? "brandId.equals="+this.search.brands[0].id : "";
            var vendor = this.search.vendors.length != 0 ? "vendorId.equals="+this.search.vendors[0].id : "";
            var query;
            query = vendor != "" ? vendor : '';
            if(brand != "") {
                if(vendor !="")
                query = query+"&"+brand
                else
                query = query+brand
            }
            if(category != "") {
                if(brand !="" || vendor !="")
                query = query+"&"+category
                else
                query = query+category
            }
            return query
        },
        setPage: function(page) {
            page = page-1;
            this.isLoading = false;
            if(this.search.brands != 0 || this.search.categories != 0 || this.type == 'discount') {
                var query =  this.getSearchQuery();
                if(this.type == 'discount') {
                    query = `${query}&isSale.equals=1`
                } else {
                    query = `${query}`
                }
                this.axios
                .get(process.env.VUE_APP_URL+`/api/public/products?page=${page}&size=16&${query}&${this.filter}`,{
                        headers: {
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(response => {
                    this.products = response
                    this.countProducts = parseInt(this.products.headers["x-total-count"]);
                    this.isLoading = true;
                })
            } else {
                this.axios
                .get(process.env.VUE_APP_URL+'/api/public/products?page='+page+'&size=16&'+this.filter,{
                    headers: {'Access-Control-Allow-Origin': '*'}
                })
                .then(response => {
                    this.products = response
                    this.isLoading = true;
                })
            }
        },
        getBrandsCategoriesVendors: function(type) {
            var isActive = "";
            if(type == "categories") { isActive = "&isActivce.equals=true&isMain.equals=true";}
            this.axios
            .get(process.env.VUE_APP_URL+'/api/public/'+type+'?size=10000&parentId.specified=false'+isActive,{
                headers: {'Access-Control-Allow-Origin': '*'}
            })
            .then(response => {
                if(type == "brands") {
                    this.brands = response.data
                }
                if(type == "categories") {
                    this.categories.subCategories = response.data
                    console.log(response.data)
                }
                if(type == "vendors") {
                    this.vendors = response.data
                }
            })
        },
        addSearch: function(type, element) {
            this.products = [];
            this.isLoading = false;
            this.countProducts = 0;
            if(type == "brands") {  
                this.search.brands.splice(0,1);
                this.search.brands.push({id : element.id, name: element.nameAr})
            }else if(type == "categories") {
                this.search.categories.splice(0,1);
                this.search.categories.push({id : element.id, name: element.nameAr})
            } else if(type == "vendors") {
                this.search.vendors.splice(0,1);
                this.search.vendors.push({id : element.id, name: element.name})
            } 
            var query = this.getSearchQuery();
            if(this.type == 'discount') {
                query = `${query}&isSale.equals=1`
            } else {
                query = `${query}`
            }
            console.log(query)
            this.axios
            .get(process.env.VUE_APP_URL+`/api/public/products?page=0&size=16&${query}&${this.filter}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                if(response.data.length != 0) {
                    this.products = response
                    console.log(response.data)
                    this.page = 1;
                    this.countProducts = parseInt(this.products.headers["x-total-count"]);
                    this.isLoading = true;
                } else {
                    this.isLoading = true;
                }
            }).catch(error => {
                console.log(error)
            })
        },
        removeSearchBrand: function(index) {
            this.search.brands.splice(index,1)
            if(this.type == 'discount') {
                this.addSearch(this.type, {id : this.typeId, name: 'تخفيضات'});
            } else 
            this.search.brands.length != 0 || this.search.categories.length != 0 || this.search.vendors.length != 0 ?  this.addSearch('', '') : this.getProducts()
        },
        removeSearchCategory: function(index) {
            this.search.categories.splice(index,1)
            if(this.type == 'discount') {
                this.addSearch(this.type, {id : this.typeId, name: 'تخفيضات'});
            } else 
            this.search.brands.length != 0 || this.search.categories.length != 0 || this.search.vendors.length != 0 ?  this.addSearch('', '') : this.getProducts()
        },
        removeSearchVendor: function(index) {
            this.search.vendors.splice(index,1)
            if(this.type == 'discount') {
                this.addSearch(this.type, {id : this.typeId, name: 'تخفيضات'});
            } else
            this.search.brands.length != 0 || this.search.categories.length != 0 || this.search.vendors.length != 0 ?  this.addSearch('', '') : this.getProducts()
        },
        removeSearchDiscount: function() {
            if(this.search.brands.length != 0 || this.search.categories.length != 0 || this.search.vendors.length != 0) {
                this.addSearch('', '')
            } else {
                this.$router.push('/products')
                this.$router.go()
            }
        },
        wishlist: function(productId) {
            this.favorites = [];
            this.getFavorites()
            var proccess =false;
            if(this.listDisplay == false) {
                if(document.getElementById("wishlist_"+productId).classList.contains('active')){
                    document.getElementById("wishlist_"+productId).classList.remove('active');
                }else {
                    document.getElementById("wishlist_"+productId).classList.add('active');
                    proccess = true;
               }   
            } else {
                if(document.getElementById("wishlist_list"+productId).classList.contains('active')){
                    document.getElementById("wishlist_list"+productId).classList.remove('active');
                }else {
                    document.getElementById("wishlist_list"+productId).classList.add('active');
                    proccess = true;
                }
            }
            this.$store.dispatch('wishlist', productId)
            this.getFavorites();
            if(proccess == true)
                this.$swal.fire(
                    'تمت العملية بنجاح!',
                    'تمت عملية ازالة المنتج الى المفضلة بنجاح',
                    'success'
                  );
            else
                this.$swal.fire(
                    'تمت العملية بنجاح!',
                    'تمت عملية ازالة المنتج بنجاح',
                    'success'
                  );
        },
        changeListDisplay: function(status) {
            if(status == true) {
                document.getElementById("grid").classList.remove("active")
                document.getElementById("list").classList.add("active")
            } else {
                document.getElementById("grid").classList.add("active")
                document.getElementById("list").classList.remove("active")
            }
            this.listDisplay = status;
        },
        loaded: function(productId) {
            document.getElementById(`imagePrLoad${productId}`).style.display = "none"
            document.getElementById(`imagePr${productId}`).style.display = "block"
        }
    }
}
</script>
