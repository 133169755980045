// const publishDay = new Date('2021-10-01');
// if(new Date() > publishDay){
    require("./assets/css/bootstrap.min.css");
    require("./assets/css/style.min.css");
    require("./assets/vendor/fontawesome-free/css/all.min.css");
    require("./assets/css/custom.css");
    window.$ = window.jQuery = require('jquery');
    require("./assets/js/bootstrap.bundle.min.js");
    // require("./assets/js/plugins.min.js");
    // require("./assets/js/main.min.js");
// } else {
    // require("./assets/css/bootstrap.min.css");
    // require("./assets/countDown/css/custom.css");
    // window.$ = window.jQuery = require('jquery');
    // require("./assets/js/bootstrap.bundle.min.js");
// }
import Vue from 'vue'
import App from './App.vue'
// import CountDown from './CountDown.vue'
import VueAxios from 'vue-axios';
import Axios from 'axios';
import routes from './router/router';
import VueSplide from '@splidejs/vue-splide';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Skeleton from 'vue-loading-skeleton';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Pagination from 'vue-pagination-2';
import store from './store.js';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faHeart, faUser, faCheck, faTimes,faArrowRight, faMapMarkerAlt, faShoppingCart, faSpinner} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

library.add(faUserSecret, faHeart, faUser, faCheck, faTimes, faArrowRight, faMapMarkerAlt, faShoppingCart, faSpinner)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueSplide);
Vue.use(VueAxios, Axios);
Vue.component('pagination', Pagination);
Vue.use(VueSweetalert2);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Skeleton)


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

const router = routes;
// if(new Date() > publishDay){
    new Vue({
        router: router,
        store: store,
        render: h => h(App),
    }).$mount('#app')
// } else {
    // new Vue({
    //     router: router,
    //     store: store,
    //     render: h => h(CountDown),
    // }).$mount('#app') 
// }

// Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}


